import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import useDesktop from '../../hooks/useDesktop';

const StyledTitle = styled.div`
  h2 {
    font-size: 1.71rem;
    font-weight: normal;
    margin-bottom: 2 rem;
    text-align: center;
  }

  p {
    font-size: 1.25rem;
    font-style: italic;
    line-height: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 2.8rem;
      margin-bottom: 4rem;
    }

    p {
      font-size: 1.7rem;
      line-height: 2.8rem;
    }
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 40%;
    margin-right: 2rem;

    h2 {
      font-size: 2rem;
      text-align: left;
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  @media screen and (min-width: 1280px) {
    flex: 0 0 40%;
    margin-right: 4rem;

    h2 {
      font-size: 2.6rem;
      text-align: left;
    }
  }
`;

interface Props {
  heading: string;
  text: string;
}

const Title: React.FC<Props> = ({ heading, text }) => {
  const isDesktop = useDesktop();
  return (
    <StyledTitle>
      <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 100 : 0}>
        <h2>{heading}</h2>
      </Fade>
      <Fade bottom duration={isDesktop ? 1000 : 0} delay={isDesktop ? 300 : 0}>
        <p>{text}</p>
      </Fade>
      <Fade
        bottom
        duration={isDesktop ? 1000 : 0}
        delay={isDesktop ? 600 : 0}
      />
    </StyledTitle>
  );
};

export default Title;
