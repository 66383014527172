import { useWindowSize } from 'react-use';
import { useState, useEffect } from 'react';

const useDesktop = (): boolean => {
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(true);
  useEffect(() => {
    setIsDesktop(width >= 1024);
  }, [width]);

  return isDesktop;
};

export default useDesktop;
