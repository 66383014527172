import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

 body {
    font-family: montserrat, sans-serif;
    color: white;
    font-display: swap;

    .fp-slidesNav ul li a span,
    #fp-nav ul li a span {
      background: white;
      left: 85%;
      }

    #fp-nav ul li:hover a span {
    background: bisque;
    }

    #fp-nav ul li:hover a.active span {
    background: white;
    }

    .fp-controlArrow.fp-prev {
      left: -2rem;
      border-width: 1rem 1rem 1rem 0;
      border-color: transparent rgba(255, 255, 255, 0.6) transparent transparent;
      transition: all 0.3s ease-in-out;
    }

    .fp-controlArrow.fp-prev:hover {
      border-width: 1.4rem 1.4rem 1.4rem 0;
      border-color: transparent white transparent transparent;
    }

    .fp-controlArrow.fp-next {
     right: -2rem;
     border-width: 1rem 0 1rem 1rem;
     border-color: transparent transparent transparent rgba(255, 255, 255, 0.6);
     transition: all 0.3s ease-in-out;
  }

    .fp-controlArrow.fp-next:hover {
     border-width: 1.4rem 0 1.4rem 1.4rem;
     border-color: transparent transparent transparent white;
  }
}
`;

const Layout: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default Layout;
