import React from 'react';
import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';

const Video = styled.video`
  left: 50%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: -998;

  @media screen and (min-aspect-ratio: 16/9) {
    width: 100vw;
    height: auto;
  }

  @media screen and (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100vh;
  }
`;

const VideoOverlay = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    radial-gradient(black 0%, transparent 50%),
    radial-gradient(black 0%, transparent 50%);
  background-size: 3px 3px;
  width: 100vw;
  height: 100vh;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -997;
`;

const VideoPosterImage = styled.div`
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: -999;

  @media screen and (min-aspect-ratio: 16/9) {
    width: 100vw;
    height: auto;
  }

  @media screen and (max-aspect-ratio: 16/9) {
    width: 177.777vh;
  }

  .gatsby-image-wrapper {
    display: block;
  }
`;

interface Props {
  video: string;
  poster?: FluidObject;
}

const BgVideo: React.FC<Props> = ({ video, poster }) => {
  return (
    <>
      <VideoOverlay />
      <VideoPosterImage>
        <Img fluid={poster} />
      </VideoPosterImage>
      <Video autoPlay loop muted>
        <source src={video} type="video/mp4" />
      </Video>
    </>
  );
};

export default BgVideo;
