import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import styled from 'styled-components';

const Icons = styled.aside`
  display: flex;
  justify-content: space-evenly;

  @media screen and (min-width: 1024px) {
    flex-flow: column nowrap;
    font-size: 1.4rem;
    margin-left: 0.85rem;
    position: fixed;
    top: 70vh;
    left: 0;

    svg {
      color: white;
      margin-top: 0.5rem;
      transition: all 0.5s ease-in-out;
    }

    svg:hover {
      transform: scale(1.5);
    }

    a:nth-child(1):hover {
      svg {
        color: #4267b2;
      }
    }

    a:nth-child(2):hover {
      svg {
        color: #38a1f3;
      }
    }

    a:nth-child(3):hover {
      svg {
        color: #ffffff;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    flex-flow: column nowrap;
    font-size: 1.4rem;
    margin-left: 0.85rem;
    position: fixed;
    top: 70vh;
    left: 0;

    svg {
      color: white;
      margin-top: 1.5rem;
      transition: all 0.5s ease-in-out;
    }

    svg:hover {
      transform: scale(1.5);
    }

    a:nth-child(1):hover {
      svg {
        color: #4267b2;
      }
    }

    a:nth-child(2):hover {
      svg {
        color: #38a1f3;
      }
    }

    a:nth-child(3):hover {
      svg {
        color: #ffffff;
      }
    }
  }
`;

const SocilaIcons: React.FC = () => (
  <Icons>
    <a
      href="https://www.facebook.com/bluemarineegypt/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaFacebookF />
    </a>
    <a
      href="https://twitter.com/bluemarineegypt"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaTwitter />
    </a>
    <a
      href="https://www.instagram.com/bluemarineegypt"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaInstagram />
    </a>
  </Icons>
);

export default SocilaIcons;
